// app/utils/queryClient.ts
import { QueryClient } from "@tanstack/react-query";

let queryClient: QueryClient;

export function getQueryClient() {
  if (!queryClient) {
    queryClient = new QueryClient({
      defaultOptions: {
        queries: {
          // Set staleTime to avoid refetching immediately on the client
          staleTime: 60 * 1000,
          // staleTime: 0,
        },
      },
    });
  }
  return queryClient;
}
